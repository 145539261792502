import { AppUserClaim } from './app-user-claims.model'

export class AuthUserDto {
  id!: number
  appUserId!: string
  userName!: string
  email!: string
  fullName!: string
  firstName!: string
  lastName!: string
  message!: string
  isAuthenticated!: boolean
  isPhoneVerified!: boolean
  isEmailVerified!: boolean
  roles!: string
  token!: string
  tokenExpiration!: string
  refreshToken!: string
  refreshTokenExpiration!: number
  userType!: number
  claims: AppUserClaim[] = []
  profileImagePath!: string
}
export class Register {
  firstName!: string
  lastName!: string
  email!: string
  password!: string
  userType = 2
}
export class AddEditPersonalInfo {
  id?: number
  firstName!: string
  lastName!: string
  dateOfBirth!: string
  yearsOfExperience!: number
  about!: string
  gender!: number
  countryId!: number
  timeZone!: string
  specialityId!: number
  serviceTypeId!: number
  countryCallingCode: string = '1'
  phoneNumber!: string
  languageId = []
}
export class AddEditCustomerAddressDto {
  customerId!: number
  addressLine1!: string
  addressLine2!: string
  adminArea1 = ''
  adminArea2 = ''
  postalCode!: string
  countryCode = 'US'
  stateId!: number
}

export class ProviderSearchFilter {
  Name?: string
  Gender?: number
  LanguageId?: number
  CountryId?: number
  SpecialityId?: number
  PageNumber?: number
  PageSize?: number
}
export class UserSignIn {
  userName!: string
  password!: string
}

export class ChangePassword {
  userId!: string
  oldPassword!: string
  newPassword!: string
}

export class ProfileComplitionDto {
  personalInfo!: boolean
  serviceInfo!: boolean
  availability!: boolean
}
export class ForgotPassword {
  userName!: string
  sendBy!: string
}
export class VerifyResetPasswordCode {
  userName!: string
  code!: string
}
export class ResetPassword {
  userName!: string
  password!: string
  confirmPassword!: string
  code!: string
}
export class VerifyEmailVerificationCode {
  code!: string
}
export class VerifyOtpDto {
  code!: string
  email!: string
}
export class ChangePasswordDto {
  userId!: string
  oldPassword!: string
  newPassword!: string
}
export class AddEditCustomerAddress {
  customerId!: number
  addressLine1!: string
  addressLine2!: string
  adminArea1!: string
  adminArea2!: string
  postalCode!: string
  countryCode!: string
  stateId!: number
}
export class EmergencyContactDto {
  customerId!: number
  emergencyContactName!: string
  emergencyContactRelationship!: string
  emergencyContactPrimaryPhoneNo!: string
  emergencyContactEmail!: string
}
export class EmailVerification {
  username!: string
  email!: string
}
